import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  Input,
} from "@chakra-ui/react";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function Login() {
  const supabaseClient = useSupabaseClient();
  const user = useUser();
  const router = useRouter();
  const [inputEmail, setEmail] = useState("");
  const [signInMessage, setSignInMessage] = useState<any>();

  useEffect(() => {
    if (user) router.push("/home");
  }, [user, router]);

  async function signInWithEmail() {
    const { data, error } = await supabaseClient.auth.signInWithOtp({
      email: inputEmail,
      options: {
        emailRedirectTo: process.env.NEXT_PUBLIC_DEPLOYMENT_URL + "/home",
      },
    });
    if (error) {
      setSignInMessage({
        type: "error",
        message:
          "We can't verify your email. Please get in touch with support.",
      });
    } else {
      setSignInMessage({
        type: "info",
        message: `We have sent an Magic Link to ${inputEmail}. Please check your inbox`,
      });
    }
  }

  return (
    <Box minH="50vh" maxH={"70vh"} w="80vw" m="auto" my={"10vh"}>
      <Auth
        redirectTo={`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/home`}
        appearance={{ theme: ThemeSupa }}
        supabaseClient={supabaseClient}
        providers={["google"]}
        socialLayout="vertical"
        magicLink
        onlyThirdPartyProviders
      />
      <Flex w={"100%"}>
        {signInMessage && signInMessage.type === "error" && (
          <Alert status={signInMessage.type}>
            {" "}
            <AlertIcon />
            <AlertDescription>{signInMessage.message}</AlertDescription>
          </Alert>
        )}
        <Input
          placeholder="Email"
          value={inputEmail}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Box>
          {" "}
          {signInMessage && signInMessage.type === "info" ? (
            <Alert status={signInMessage.type}>
              <AlertIcon />
              <AlertDescription>{signInMessage.message}</AlertDescription>
            </Alert>
          ) : (
            <Button onClick={signInWithEmail}>Sign in</Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
}
